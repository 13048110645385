<template>
  <section>
    <div class="ll-topbox">
      <!-- breadcrumb -->
      <div class="breadcrumb-top">
        <b-breadcrumb class="">
          <b-breadcrumb-item :to="{ name: 'dashboard' }">
            <feather-icon
                icon="HomeIcon"
                size="16"
            />
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            {{ $t('Report') }}
          </b-breadcrumb-item>
          <b-breadcrumb-item active>
            {{ $t('Download Invoice') }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>

    <b-row>
      <!-- Search and result area -->
      <b-col md="8">
        <b-card class="card-browser-states ll-fHei ll-card">
          <b-card-body>
            <b-row>
              <!-- Filter -->
              <b-col md="4">
                {{ $t('Customer') }}
                <v-select
                    v-model.trim="customer"
                    :options="customerList"
                    label="value"
                    @search="queryCustomer"
                    @input="getCustomer"
                    placeholder="Search by OrgID/Cust. Name/FOX Acct. No."
                    multiple
                >
                </v-select>
              </b-col>
              <b-col md="4">
                {{ $t('Document Type') }}
                <v-select
                    v-model.trim="documentType"
                    :options="documentTypeList"
                    label="documentType"
                    @input="setDocumentType"
                    placeholder="Select Document Type"
                    multiple
                >
                </v-select>
              </b-col>
              <b-col md="4">
                {{ $t('Invoice Number') }}
                <b-form-input
                    v-model="condition.documentNumber"
                    placeholder="Enter Invoice Number"
                />
              </b-col>
              <b-col md="4" class="mt-md-1">
                {{ $t('Invoice Date') }}
                <flat-pickr
                    v-model="tempCondition.documentDate"
                    class="form-control"
                    :config="dateRangeConfig"
                    @on-change="onChangeDocumentDate"
                />
              </b-col>
              <b-col md="4" class="mt-md-1">
                {{ $t('Billing Month') }}
                <flat-pickr
                    v-model="tempCondition.documentEffectiveDate"
                    class="form-control"
                    :config="monthConfig"
                    @on-change="onChangeDocumentEffectiveDate"
                />
              </b-col>
              <b-col md="4" class="mt-md-1">
                {{ $t('Latest Update Date') }}
                <flat-pickr
                    v-model="tempCondition.updatedAt"
                    class="form-control"
                    :config="dateRangeConfig"
                    @on-change="onChangeUpdatedAt"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <div class="ll-btn">
                  <b-dropdown
                      :text="$t('Batch Operation')"
                      variant="info"
                      class="ll-batch my-0 ml-0 p-0"
                  >

                    <b-dropdown-item @click="batchDownloadOrganizationDocument" v-if="">{{ $t('Download Selected') }}</b-dropdown-item>
                  </b-dropdown>
                  <b-button
                      variant="secondary"
                      class="mr-1"
                      @click="onClear"
                  >
                    {{ $t('Clear') }}
                  </b-button>
                  <b-button variant="primary"
                            @click="queryList">
                    {{ $t('Search') }}
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-table
            ref="selectableTable"
            class="position-relative ll-table"
            :items="rows"
            :fields="columns"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
            :striped="true"
            :hover="true"
            selectable
            @row-selected="onRowSelected"
            :select-mode="selectMode"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @sort-changed="onSortChange"
            :sticky-header="true"
            :busy="isBusy"
        >
          <template v-slot:head(selected)="data">
            <b-form-checkbox @input="selectAllRowsData">
            </b-form-checkbox>
          </template>
          <template v-slot:cell(selected)="{rowSelected, index}">
            <b-form-checkbox :checked="rowSelected?true:false" @change="selectRowData(rowSelected, index)">
            </b-form-checkbox>
          </template>
          <template v-slot:cell(documentEffectiveDate)='row'>
            <!-- show in YYYY-mm format -->
            <span>{{ new Date(row.item.documentEffectiveDate).toISOString().slice(0,7) }}</span>
          </template>
          <template v-slot:cell(documentUrls)='row'>
            <b-link v-for="documentUrl in JSON.parse(row.item.documentUrls)" :href="documentUrl" target="_blank">
              <svg class="icon ll-imgs" aria-hidden="true">
                <use xlink:href="#icon-Waybill"></use>
              </svg>
            </b-link>
          </template>
        </b-table>
        <div class="d-flex justify-content-between flex-wrap mt-1">
          <div class="d-flex align-items-center mb-0 mr-1">
        <span class="text-nowrap ">
          Showing 1 to
        </span>
            <b-form-select
                v-model="size"
                :options="pageOptions"
                class="mx-1"
                @input="handlePageChange"
            />
            <span class="text-nowrap"> of {{ totalSize }} entries </span>
          </div>
          <div>
            <b-pagination
                v-model="page"
                :total-rows="totalSize"
                :per-page="size"
                first-number
                last-number
                class="mb-0 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-col>

      <!-- Download progress area -->
      <b-col md="4">
        <b-card no-body>
          <b-card-header>
            <b-card-title>{{ $t('Task List') }}</b-card-title>
          </b-card-header>
          <b-card-body >
            <b-table :items="items" :fields="taskFields"
                     :borderless='false' :hover="true"
                     :sticky-header="true"
                     class="ll-table"
                     :busy="isBusy"
            >
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
              <template #cell(action)="scope">
                <b-button
                    class="btn-icon"
                    variant="flat-danger"
                    @click="del(scope.item.id)"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
                <b-button
                    class="btn-icon"
                    variant="flat-info"
                    :href="scope.item.output"
                    :disabled="scope.item.output===null"
                    :style="{'cursor': (scope.item.output===null) ? 'no-drop' : 'pointer'}"
                >
                  <feather-icon icon="ArrowDownIcon" />
                </b-button>
              </template>
              <template #cell(progressNow)="scope">
                <b-progress
                    key="info"
                    :max="scope.item.progressMax"
                    :variant="(scope.item.progressStatus==='success') ? 'success' : ((scope.item.progressStatus==='fail')?'danger':'info')"
                    :class="(scope.item.progressStatus==='success') ? 'progress-bar-success' : ((scope.item.progressStatus==='fail')?'progress-bar-danger':'progress-bar-info')"
                >
                  <b-progress-bar
                      :value="scope.value"
                      :variant="(scope.item.progressStatus==='success') ? 'success' : ((scope.item.progressStatus==='fail')?'danger':'info')"
                      :label="`${((scope.item.progressNow/scope.item.progressMax) * 100).toFixed(2)}%`"
                  />
                </b-progress>
              </template>
              <template #cell(input)="scope">
                <span>{{(scope.item.createdAt)}}</span>
              </template>
              <template #cell(result)="scope">
                <span>{{scope.item.progressStatus}}</span>
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BFormTextarea,
  BRow, BCol, BCard, BFormGroup, BFormInput, BBadge, BTable,
  BButton, BSidebar, BInputGroupPrepend, BInputGroup,
  BCardHeader, BCardTitle, BCardBody, VBToggle, BTab, BTabs,
  BPagination, BFormSelect, BAvatar, BFormCheckbox, BFormSelectOption, BModal, VBModal,
  BDropdown, BDropdownItem, BOverlay, BCollapse, BSpinner, BLink,
  BProgress, BProgressBar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import vSelect from 'vue-select'
import Overview from '../lastMile/components/overview.vue'
import Operation from '../lastMile/components/operation.vue'
import Package from '../lastMile/components/package.vue'
import Item from '../lastMile/components/item.vue'
import {debounce} from "@/libs/fun.js"
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import permission from '@core/directives/permission/index.js'
import axios from 'axios'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import {formatDate} from "../../../@core/utils/filter";

export default {
  components: {
    BProgress,
    BProgressBar,
    Overview,
    Operation,
    Package,
    Item,
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCollapse,
    BBadge,
    BButton,
    BLink,
    BSpinner,
    BTable,
    flatPickr,
    monthSelectPlugin,
    BSidebar,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
    BFormTextarea,
    BTab,
    BTabs,
    BPagination,
    BFormSelect,
    BAvatar,
    AppCollapse,
    AppCollapseItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormCheckbox,
    BFormSelectOption,
    BModal,
    BDropdown,
    BDropdownItem,
    BOverlay,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
    'b-modal': VBModal,
    permission,
  },
  data() {
    return {
      selectMode: 'multi',
      window: {
        width: 0,
        height: 0
      },
      disabled: true,
      dateConfig: {
        dateFormat: 'Y-m-d H:i:ss',
        mode: 'range',
        defaultDate: [(new Date).setDate((new Date).getDate() - 2), new Date()],
        // enableTime: true, // 启用时间选择器
      },
      dateRangeConfig: {
        dateFormat: 'Y-m-d',
        mode: 'range',
        defaultDate: null,
        // enableTime: true, // 启用时间选择器
      },
      monthConfig: {
        dateFormat: 'Y-m',
        defaultDate: null,
        enableMonth: true,
        plugins: [
          new monthSelectPlugin({
            shorthand: true,
            dateFormat: "Y-m",
          })
        ]
      },
      condition: {
        page: '1',
        size: '100',
        orderBy: 'createdAt',
        sequence: 'desc',
        orgIds: null,
        documentTypes: null,
        documentDateFrom: null,
        documentDateTo: null,
        documentNumber: null,
        documentEffectiveDateFrom: null,
        documentEffectiveDateTo: null,
        updatedAtFrom: null,
        updatedAtTo: null,
      },
      tempCondition: {
        documentEffectiveDate: null,
        documentDate: '',
        updatedAt: '',
      },
      time: '',
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageOptions: [50, 100, 200, 500],
      page: null,
      size: 100,
      totalSize: null,
      totalPage: null,
      selected: [],
      color: 'info',
      windowWidth: document.documentElement.clientWidth,  //屏幕宽度
      windowHeight: document.documentElement.clientHeight,   //屏幕高度
      height: document.documentElement.clientHeight * 0.67,
      hei: (document.documentElement.clientHeight * 0.67 - 46) / 10,
      heiVal: null,
      hei1: document.documentElement.clientHeight * 0.12,
      customerList: [],
      customer: '',
      documentTypeList: [],
      documentType:'',
      pkg: false,
      type: null,
      nums: 1,
      loadingList: false,
      domObj: null,
      isTbox: true,
      isBusy: true,
      baseURL: axios.defaults.baseURL,
      isAccess: true,
      isAccess2: true,
      documentNumbers: [],
      items: [],
      orgIds: [],
      selectAll: false,
      rows: [],
      columns: [
        {key: 'selected', label: 'selected'},
        {key: 'orgID', label: this.$t('Org ID'), width: "200"},
        {key: 'orgName', label: this.$t('Customer Name'), width: "180"},
        {key: 'keAccountNumber', label: this.$t('FOX Account'), width: "auto"},
        {key: 'documentType', label: this.$t('Document Type'), width: "200"},
        {key: 'documentDate', label: this.$t('Invoice Date'), width: "auto"},
        {key: 'documentNumber', label: this.$t('Invoice Number'), width: "auto"},
        {key: 'documentEffectiveDate', label: this.$t('Billing Month'), width: "auto"},
        {key: 'remark', label: this.$t('Remark'), width: "auto"},
        {key: 'updatedAt', label: this.$t('Latest Update Date'), width: "150"},
        {key: 'documentUrls', label: this.$t('Documents'), width: "80"},
      ],

      taskFields: [
        { key: 'input', label: 'Requested At' },
        { key: 'progressNow', label: 'Progress Now', class: 'll-progress' },
        { key: 'result', label: 'Result' },
        { key: 'action', label: this.$t('Action') }
      ],

    }
  },
  computed: {},
  watch: {
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    clearInterval(this.timer)
  },
  mounted() {
    this.getList()
    this.queryCustomer()
    this.getExportList()
    this.timer = setInterval(() => {
      setTimeout(() => {
        this.getExportList()
      }, 0)
    }, 5000)
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        window.fullWidth = document.documentElement.clientWidth
        this.windowHeight = window.fullHeight
        this.windowWidth = window.fullWidth
      })()
    }
  },
  beforeDestroy() {
    if (!this.domObj) return
    if (this.domObj.attachEvent) {
      this.domObj.detachEvent('onmousewheel', this.mouseScroll)
    }
    if (this.domObj.addEventListener) {
      this.domObj.removeEventListener('DOMMouseScroll', this.mouseScroll, false)
    }
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
      const arr1 = []
      items.map(i => {
        arr1.push(i.documentNumber)
      })
      this.documentNumbers = arr1
      // console.log(this.documentNumbers)
    },
    selectRowData(rowSelected, index) {
      if (rowSelected == false) {
        this.$refs.selectableTable.selectRow(index)
      } else {
        this.$refs.selectableTable.unselectRow(index)
      }
    },
    selectAllRowsData(val) {
      this.selectAll = val ? true : false
      if (val == true) {
        this.$refs.selectableTable.selectAllRows()
      }
      if (val == false) {
        this.$refs.selectableTable.clearSelected()
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    // 报表任务列表
    getExportList() {
      this.$http.get('exportReport/getExportProcessStatus/OrganizationDocumentReport').then(res=>{
        this.items = res.data.data
        this.isBusy = false
      })
    },
    del(id) {
      this.box = ''
      this.$bvModal.msgBoxConfirm('Please confirm that you want to delete .', {
        title: 'Handle',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        if (value) {
          this.box = value
          this.$http.delete(`/exportReport/delete/${id}`).then(res=>{
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Delete Success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.getExportList()
          })
        }
      })
    },
    mouseScroll(event) { // google 浏览器下
      // 向上滚 event.wheelDelta = 120
      // 向下滚 event.wheelDelta = -120
      if (event.wheelDelta > 0) {
        this.isTbox = true
      } else {
        this.isTbox = false
      }
    },
    // 默认日期格式转换
    dateFormats() {
      const toDate = new Date()
      let datetime = toDate.getFullYear() + "-" +
          (toDate.getMonth() > 8 ? (toDate.getMonth() + 1) : "0" + (toDate.getMonth() + 1)) + "-"
          + (toDate.getDate() > 9 ? (toDate.getDate()) : "0" + (toDate.getDate()))
          + ' ' + '23:59:59'
      let dateString = toDate.getFullYear() + "-" +
          (toDate.getMonth() > 8 ? (toDate.getMonth() + 1) : "0" + (toDate.getMonth() + 1)) + "-"
          + (toDate.getDate() > 9 ? (toDate.getDate()) : "0" + (toDate.getDate()))

      const fromDate = new Date((new Date).setDate((new Date).getDate() - 15))
      let datetime1 = fromDate.getFullYear() + "-" +
          (fromDate.getMonth() > 8 ? (fromDate.getMonth() + 1) : "0" + (fromDate.getMonth() + 1)) + "-"
          + (fromDate.getDate() > 9 ? (fromDate.getDate()) : "0" + (fromDate.getDate()))
          + ' ' + '00:00:00'
      let dateString1 = fromDate.getFullYear() + "-" +
          (fromDate.getMonth() > 8 ? (fromDate.getMonth() + 1) : "0" + (fromDate.getMonth() + 1)) + "-"
          + (fromDate.getDate() > 9 ? (fromDate.getDate()) : "0" + (fromDate.getDate()))

      this.tempCondition.documentDate = [dateString1, dateString]
      this.condition.documentDateFrom = datetime1
      this.condition.documentDateTo = datetime
    },
    // 查询列表
    queryList() {
      this.loadingList = true
      this.isBusy = true
      this.$http.post('/orgDoc/searchInvoice', this.condition).then(res => {
        // console.log(res.data.data)
        const info = res.data.data
        this.rows = info.content
        this.page = info.page
        this.size = info.size
        this.totalSize = info.totalSize
        this.totalPage = info.totalPage
        this.loadingList = false
        this.isBusy = false
      }).catch(error => console.log(error))
    },
    // 首次查询
    getList() {
      this.dateConfig.dateFormat = 'Y-m-d'
      this.dateFormats()
      this.queryList()
      this.queryDocumentType()
    },
    // 根据日期查询
    onChangeDocumentDate(selectedDates, dateStr, instance) {
      if (selectedDates.length == 1) {
        let later = new Date(selectedDates[0].getTime() + 86400000 * 90)
        instance.config.minDate = selectedDates[0].toUTCString()
        instance.config.maxDate = later.toUTCString()
      } else if (selectedDates.length == 2) {
        instance.config.minDate = undefined
        instance.config.maxDate = undefined
        this.condition.documentDateFrom = this.formatDate(selectedDates[0]) + ' 00:00:00'
        this.condition.documentDateTo = this.formatDate(selectedDates[1]) + ' 23:59:59'
        this.condition.updatedAtFrom = null
        this.condition.updatedAtTo = null
        this.condition.documentEffectiveDateFrom = null
        this.condition.documentEffectiveDateTo = null
        this.tempCondition.updatedAt = null
        this.tempCondition.documentEffectiveDate = null
        this.time = ""
        this.queryList()
      }
    },
    onChangeDocumentEffectiveDate(selectedDate) {
      if (selectedDate.length > 0) {
        let date = new Date(selectedDate);
        this.condition.documentEffectiveDateFrom = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2,'0') + '-01 00:00:00'
        date.setMonth(date.getMonth() + 1);
        this.condition.documentEffectiveDateTo = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2,'0') + '-01 00:00:00'
        this.condition.documentDateFrom = null
        this.condition.documentDateTo = null
        this.condition.updatedAtFrom = null
        this.condition.updatedAtTo = null
        this.tempCondition.documentDate = null
        this.tempCondition.updatedAt = null
      }
      this.queryList()
    },

    onChangeUpdatedAt(selectedDates, dateStr, instance) {
      if (selectedDates.length == 1) {
        let later = new Date(selectedDates[0].getTime() + 86400000 * 90)
        instance.config.minDate = selectedDates[0].toUTCString()
        instance.config.maxDate = later.toUTCString()
      } else if (selectedDates.length == 2) {
        instance.config.minDate = undefined
        instance.config.maxDate = undefined
        this.condition.updatedAtFrom = this.formatDate(selectedDates[0]) + ' 00:00:00'
        this.condition.updatedAtTo = this.formatDate(selectedDates[1]) + ' 23:59:59'
        this.condition.documentDateFrom = null
        this.condition.documentDateTo = null
        this.condition.documentEffectiveDateFrom = null
        this.condition.documentEffectiveDateTo = null
        this.tempCondition.documentEffectiveDate = null
        this.tempCondition.documentDate = null

        this.time = ""
        this.queryList()
      }
    },
    handleChangePage(page) {
      this.condition.page = page.toString()
      this.nums = 1
      this.queryList()
    },
    handlePageChange(active) {
      this.condition.page = '1'
      this.condition.size = active
      this.queryList()
    },
    onClear() {
      let ignoredKeys = ['page', 'size', 'orderBy', 'sequence'];
      // iterate this.condition and set all values to null
      for (let key in this.condition) {
        if (ignoredKeys.includes(key)) continue;
        this.condition[key] = null
      }
      for (let key in this.tempCondition) {
        this.tempCondition[key] = null
      }
      this.customer = ''
      this.documentType = ''
      // reset date range as default
      this.dateFormats();
    },
    queryDocumentType() {
      this.$http.get('/orgDoc/getInvoiceDocTypeList').then(res => {
        this.documentTypeList = res.data.data
      })
    },
    setDocumentType(d) {
      const arr = []
      d.map(item => {
        arr.push(item.documentType)
      })
      this.condition.documentTypes = arr
    },
    queryCustomer(d) {
      let condition = d ? d : null
      this.$http.get('/admin/settings/organization/getCacheOrganization?condition=' + condition).then(res => {
        this.customerList = res.data.data
      })

    },
    getCustomer(d) {
      const arr = []
      d.map(item => {
        arr.push(item.key)
      })
      this.condition.orgIds = arr
    },

    batchDownloadOrganizationDocument() {
      if (this.documentNumbers.length <= 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please check the row',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return
      }
      if (this.selectAll) {;
        let obj = {}
        obj.all = true
        obj.page = this.condition
        obj.documentNumberList = null
        this.batchDownloadOrganizationDocument1(obj)
      } else {
        let obj = {}
        obj.all = false
        obj.page = this.condition
        obj.documentNumberList = this.documentNumbers
        this.batchDownloadOrganizationDocument1(obj)
      }
      this.getExportList()
    },
    batchDownloadOrganizationDocument1(p) {
      // console.log(p)
      this.$http.post('/exportReport/organizationDocumentReport', p).then(res => {
        if (res.data.code == '200') {
        }
      })
    },
    // 排序规则
    onSortChange(params) {
      const orderBy = params.sortBy
      const orderMole = params.sortDesc ? 0 : 1
      this.condition.orderBy = orderBy
      this.condition.orderMole = orderMole
      this.getList()
    },
    getAccess() {
      const userData = JSON.parse(localStorage.getItem('userInfo'))
      if (userData.organizationName == 'KEC') {
        this.isAccess = true
        this.isAccess2 = true
      } else {
        this.isAccess = false
        this.isAccess2 = false
      }
    },
    formatDate(date) {
      let d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    }
  },
}
</script>
<style scoped>
.ll-fail {
  margin: auto;
}

.ll-switch {
  width: 100px;
}

.ll-cardHei1 > .card-body {
  padding: 0;
}

.ll-tBox {
  /* position: fixed; */
  height: 46px;
  z-index: 9;
  /* width: 400px; */
  background-color: #fff;
  padding-left: 0.5rem;
}

.ll-cbox {
  margin-top: 10px;
  overflow-y: auto;
  border-top: 1px solid #d9d9d9;
}

.ll-batch {
  /* position: absolute;
  right: 14rem;
  z-index: 9;
  top: 6rem; */
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0.5rem 0;
}

.ll-badge {
  /* padding: 2px; */
  margin-right: 2px;
  font-size: 14px;
}

.ll-searchBtn div {
  background-color: #ed6d01;
  color: #fff;
  width: 60px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.ll-searchBtn div:active {
  opacity: 0.8;
}

.ll-fHei {
  /*height: 124px;*/
  height: var(--height);
  margin-bottom: 10px;
}

.icon {
  width: 2em;
  height: 2em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.ll-conBox {
  display: flex;
  align-items: center;
}

.ll-box {
  margin-left: 10px;
}

.ll-box span {
  display: block;
  text-align: center;
}

.ll-newbox {
  text-align: center;
}

.ll-newbox .ll-tit {
  font-size: 16px;
  font-weight: bold;
  padding: 5px 0;
}

.ll-fHei > .card-body {
  display: flex;
  align-items: center;
}

.ll-cardBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ll-card > .card-body {
  padding: 10px 5px;
  border-radius: 5px;
}

.ll-fpickr {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 7px;
}

.ll-tabs .nav-pills .nav-link.active {
  background-color: unset;
  color: #5e5873;
  box-shadow: none;
  border: none;
  border-bottom: 3px solid #ed6d01;
  border-radius: 0px;
}

.ll-tabs .nav-item {
  font-weight: bold;
}

.ll-btn {
  bottom: 10px;
  display: flex;
  justify-content: end;
  width: 100%;
  margin-top: 10px;
}

.ll-tkid {
  color: #161d31;
}

.ll-cardList {
  margin-bottom: 5px;
}

.ll-cardList .card-header {
  padding: 0.5rem;
}

.ll-cardList .ll-cardBody {
  padding: 0.5rem;
}

.ll-cardHei {
  /* height: 100%; */
  overflow-y: auto;
}

.ll-page {
  position: absolute;
  bottom: -10px;
  /* left: 15%; */
}

.ll-filter {
  padding: 0 1rem 0 1rem;
}

.ll-filter .v-select {
  margin-top: 7px;
}

.ll-cardActive {
  background: #f6f6f6;
}

.ll-backg {
  /* background: #f6f6f6; */
  height: 85vh;
}

.ll-0 + div {
  display: none;
}

.ll-rowbox {
  width: 100%;
  margin-left: 0;
}

.ll-rowbox .ll-col1 {
  text-align: left;
  padding: 0;
  font-size: 13px;

}

.ll-rowbox .ll-col2 {
  text-align: left;
  padding: 0;
  font-size: 13px;
  font-weight: 700;
}

.ll-rowbox .ll-col3 {
  text-align: left;
  padding: 0;
  font-size: 13px;

}

.ll-rowbox .ll-col4 {
  text-align: left;
  padding: 0;
  font-size: 13px;

}

.ll-rowbox .ll-col5 {
  text-align: left;
  padding: 0;
  font-size: 13px;

}

.ll-rowbox .ll-col6 {
  text-align: left;
  padding: 0;
  font-size: 13px;

}

.ll-custom {
  padding: 0 10px;
}

.ll-numSel {
  height: auto;
}

.ll-pg {
  width: 30%;
}

.ll-outline > div {
  background: var(--background);
  margin-right: 1px;
}

.ll-hover {
  cursor: pointer;
}

/* .ll-hover:hover{
  color: #ed6d01;
} */
.ll-fontbold {
  font-weight: bold;
}

</style>
<style>
[dir] .table-hover tbody tr {
  font-size: 14px !important;
}

[dir] .table td {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

[dir] .table.b-table > tbody .b-table-row-selected.table-active td {
  background-color: unset;
  background: unset;
}

[dir] .table.b-table.b-table-selectable:not(.b-table-selectable-no-click) > tbody > tr {
  background-color: unset;
  background: unset;
}

.ll-eltable th {
  padding: 0;
}

.ll-sortCodeBox {
  width: 20%;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th {
  vertical-align: middle;
}

.ll-tabs .nav-item {
  font-weight: bold;
}

.ll-collapse .card {
  box-shadow: none;
}

.ll-text {
  margin: auto;
}

[dir] .custom-select {
  border-radius: 0;
}

[dir] .input-group:not(.bootstrap-touchspin):focus-within .form-control,
[dir] .input-group:not(.bootstrap-touchspin):focus-within .input-group-text,
[dir] .input-group:not(.bootstrap-touchspin):focus-within .custom-select {
  border-color: #ed6d01;
  box-shadow: none;
}

.ll-row {
  flex-wrap: nowrap;
}

.action-button {
  margin: 10px;
}

.ll-rowbox.order-detail {
  padding: 10px;
  border-radius: 0.428rem;
  font-size: 14px;
  margin-top: 10px;
}

.card.card-browser-states.ll-card.ll-cardList.ll-hover.order-detail.bg-white {
  box-shadow: none;
  font-size: 12px;
  border-radius: 5px;
  margin: 10px 0px;
}

.card.card-browser-states.ll-card.ll-cardList.ll-hover.order-detail.bg-white .card-body:hover {
  background: #eeeeee;
}

.modal-lg {
  max-width: 90%;
}

/* .modal-content {
  height: 90vh;
} */
.ll-tkid.column-title {
  font-weight: bold;
  font-size: 14px;
}

td.small-cell {
  padding-left: 40px !important;
  width: 10%;
}

td.extra-small-cell {
  padding-left: 13px !important;
  width: 5%;
}

td.small-cell span {
  padding-left: 7px;
}

.ll-wrapper {
  height: 35vh !important;
}

.ll-tableHei {
  height: 30vh !important;
}

.badge-tag {
  background: #c95300fa;
  color: #fff;
  margin-right: 4px !important;
  margin-bottom: 3px;
}

.tag-set {
  float: right;
  margin-top: 10px;
}
</style>
